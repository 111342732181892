<template>
  <div class="home">
    <b-container class="mt-10" fluid>
      <h3 class="py-2">
        <span class="text-capitalize">&nbsp;&nbsp;</span>
      </h3>
      <hr />
      <b-row>
        <b-col md="12">
          <div class="my-cart mb-4 mt-5">
            <h3>Parts Ordering</h3>

            <div v-if="currentStep === 1">
              showing step 1 content
              <h4 class="mt-5">
                Select Order Type
              </h4>

              <b-form-radio-group
                v-model="formData.orderType"
                :options="[
                  'Repair Order',
                  'Stock Replenishment',
                  'Created Demand'
                ]"
                class="mt-2"
              ></b-form-radio-group>

              <h4 class="mt-4">
                Select Order Mode
              </h4>

              <b-form-radio-group
                v-model="formData.orderMode"
                :options="['Air Order', 'Sea Order']"
                class="mt-2"
              ></b-form-radio-group>

              <div v-if="formData.orderType && formData.orderMode">
                <b-row class="mt-4">
                  <b-col
                    md="3"
                    class="align-items-center"
                    v-if="formData.orderType === 'Repair Order'"
                  >
                    <div class="d-flex align-items-center">
                      <label>Job No.:</label>
                      <b-form-input
                        class="ml-2 flex-1"
                        v-model="newItem.JobNo"
                      ></b-form-input>
                    </div>
                  </b-col>

                  <b-col
                    md="3"
                    v-if="
                      formData.orderType === 'Repair Order' ||
                        formData.orderType === 'Created Demand'
                    "
                  >
                    <div class="d-flex align-items-center">
                      <label>VIN:</label>
                      <b-form-input
                        class="ml-2 flex-1"
                        v-model="newItem.VIN"
                      ></b-form-input>
                    </div>
                  </b-col>

                  <b-col md="3" class="align-items-center">
                    <div class="d-flex align-items-center">
                      <label>Part No.:</label>
                      <b-form-input
                        class="ml-2 flex-1"
                        v-model="newItem.PartNo"
                      ></b-form-input>
                    </div>
                  </b-col>

                  <b-col md="3">
                    <div class="d-flex align-items-center">
                      <label>Quantity:</label>
                      <b-form-input
                        class="ml-2 flex-1"
                        v-model="newItem.Quantity"
                        type="number"
                      ></b-form-input>
                      <b-button variant="info" class="ml-2" @click="addItem">
                        Add
                      </b-button>
                    </div>
                  </b-col>
                </b-row>

                <b-container
                  fluid
                  :style="{
                    minHeight: '200px',
                    minWidth: '100%',
                    display: 'flex',
                    placeItems: 'center'
                  }"
                  v-if="gettingPartDetails"
                >
                  <br />
                  <b-spinner
                    label="Loading..."
                    style="margin: 0 auto"
                  ></b-spinner>
                </b-container>

                <div v-else>
                  <table
                    class="vehicle-table table b-table table-striped table-hover mt-4"
                    v-if="formData.orderType === 'Stock Replenishment'"
                  >
                    <thead>
                      <tr>
                        <th>Part No.</th>
                        <th>Part Name</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Del</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, i) in stockReplenishmentItems"
                        :key="item.PartNo"
                      >
                        <td>{{ item.PartNo }}</td>
                        <td>{{ item.PartName }}</td>
                        <td>{{ item.UnitCost }}</td>
                        <td>{{ item.Quantity }}</td>
                        <td>
                          <b-button
                            variant="danger"
                            @click="deleteItem(stockReplenishmentItems, i)"
                          >
                            Delete
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    class="vehicle-table table b-table table-striped table-hover mt-4"
                    v-else-if="formData.orderType === 'Created Demand'"
                  >
                    <thead>
                      <tr>
                        <th>Part No.</th>
                        <th>Part Name</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>VIN No.</th>
                        <th>Del</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(item, i) in createdDemandItems"
                        :key="item.PartNo"
                      >
                        <td>{{ item.PartNo }}</td>
                        <td>{{ item.PartName }}</td>
                        <td>{{ item.UnitCost }}</td>
                        <td>{{ item.Quantity }}</td>
                        <td>{{ item.VIN }}</td>
                        <td>
                          <b-button
                            variant="danger"
                            @click="deleteItem(createdDemandItems, i)"
                          >
                            Delete
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-else-if="formData.orderType === 'Repair Order'">
                    <div
                      v-for="(items, jobNumber) in repairOrderItems"
                      :key="jobNumber"
                      class="mt-5"
                    >
                      <div>
                        <h5>Job Number: {{ jobNumber }}</h5>
                        <table
                          class="vehicle-table table b-table table-hover mt-4"
                        >
                          <thead>
                            <tr>
                              <th>Part No.</th>
                              <th>Part Name</th>
                              <th>Unit Price</th>
                              <th>Quantity</th>
                              <th>VIN No.</th>
                              <th>Del</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="(item, i) in items" :key="i">
                              <td>{{ item.PartNo }}</td>
                              <td>{{ item.PartName }}</td>
                              <td>{{ item.UnitCost }}</td>
                              <td>{{ item.Quantity }}</td>
                              <td>{{ item.VIN }}</td>
                              <td>
                                <b-button
                                  variant="danger"
                                  @click="deleteItem(items, i)"
                                >
                                  Delete
                                </b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button
                  variant="info"
                  @click="submitPartNumbers"
                  class="mt-5"
                >
                  Submit
                </b-button>
              </div>
            </div>

            <div v-if="currentStep === 2">
              showing step 2 content
              <table
                class="vehicle-table table b-table table-striped table-hover mt-4"
                v-if="formData.orderType === 'Stock Replenishment'"
              >
                <thead>
                  <tr>
                    <th>Part No.</th>
                    <th>Part Name</th>
                    <th>Quantity</th>
                    <th>Unit Cost</th>
                    <th>Suggested Substitute</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="item in stockReplenishmentItems"
                    :key="item.PartNo"
                  >
                    <td>{{ item.PartNo }}</td>
                    <td>
                      {{ partsDetails[item.PartNo].Description }}
                    </td>
                    <td>{{ item.Quantity }}</td>

                    <td>
                      {{ partsDetails[item.PartNo].UnitCost }}
                    </td>
                    <td>
                      {{ partsDetails[item.PartNo].Substitutes }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                class="vehicle-table table b-table table-striped table-hover mt-4"
                v-else-if="formData.orderType === 'Created Demand'"
              >
                <thead>
                  <tr>
                    <th>Part No.</th>
                    <th>Part Name</th>
                    <th>Quantity</th>
                    <th>VIN No.</th>
                    <th>Unit Cost</th>
                    <th>Suggested Substitute</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in createdDemandItems" :key="item.PartNo">
                    <td>{{ item.PartNo }}</td>
                    <td>{{ partsDetails[item.PartNo].Description }}</td>
                    <td>{{ item.Quantity }}</td>
                    <td>{{ item.VIN }}</td>
                    <td>
                      {{ partsDetails[item.PartNo].UnitCost }}
                    </td>
                    <td>
                      {{ partsDetails[item.PartNo].Substitutes }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else-if="formData.orderType === 'Repair Order'">
                <div
                  v-for="(items, jobNumber) in repairOrderItems"
                  :key="jobNumber"
                  class="mt-5"
                >
                  <div>
                    <h5>Job Number: {{ jobNumber }}</h5>
                    <table class="vehicle-table table b-table table-hover mt-4">
                      <thead>
                        <tr>
                          <th>Part No.</th>
                          <th>Part Name</th>
                          <th>Quantity</th>
                          <th>VIN No.</th>
                          <th>Unit Cost</th>
                          <th>Sugested Substitute</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="item in items" :key="item.PartNo">
                          <td>{{ item.PartNo }}</td>
                          <td>{{ item.PartName }}</td>
                          <td>{{ item.Quantity }}</td>
                          <td>{{ item.VIN }}</td>
                          <td>{{ partsDetails[item.PartNo].UnitCost }}</td>
                          <td>
                            {{ partsDetails[item.PartNo].Substitutes }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="ml-5 mt-4">
                <b-button variant="danger" @click="currentStep = 1">
                  Add or Remove
                </b-button>
                <b-button variant="danger" class="ml-5" @click="placeOrder">
                  <template v-if="placingOrder">
                    <b-spinner small type="grow"></b-spinner>
                    Loading...
                  </template>
                  <template v-else>
                    Place Order
                  </template>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
// import config from "../config";
import VehicleService from "../service/vehicle";
import { getData } from "./../utils/storage";
// import TheModal from "../components/TheModal.vue";

export default {
  data() {
    return {
      locationModal: false,
      printModal: false,
      steps: [
        {
          number: 1,
          text: ""
        }
      ],
      currentStep: 1,
      formData: {
        orderType: "",
        orderMode: ""
      },
      items: [],
      repairOrderItems: {},
      stockReplenishmentItems: [],
      createdDemandItems: [],
      // partsNumber: "1223,43253434,6456",
      // partsDetails: [],
      gettingDetails: false,
      gettingPartDetails: false,
      gotDetails: false,
      newItem: {
        JobNo: "",
        VIN: "",
        PartNo: "",
        Quantity: ""
      },
      partsDetails: {},
      placingOrder: false
    };
  },
  created() {
    // const loggedInUser = getData("userInfo");
  },
  computed: {},
  methods: {
    showToast(text, variant = "danger") {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true
      });
    },

    showSuccess(text, variant = "success") {
      this.$bvToast.toast(text, {
        title: `Success`,
        variant: variant,
        solid: true
      });
    },

    placeOrder() {
      //TODO: implement place order functionality

      const orderTypeInNumbers = {
        "Repair Order": 0,
        "Stock Replenishment": 1,
        "Created Demand": 2
      };

      const orderModeInNumbers = {
        "Air Order": 0,
        "Sea Order": 1
      };

      const orderItems = Object.keys(this.partsDetails).map(PartNo => {
        return this.partsDetails[PartNo];
      });

      const loggedInUser = getData("userInfo");
      const user = loggedInUser.DealerUserID;
      console.log("loggedInUser:", loggedInUser);

      const payload = {
        OrderType: orderTypeInNumbers[this.formData.orderType],
        OrderMode: orderModeInNumbers[this.formData.orderMode],
        OrderItems: orderItems,
        User: user,
        DealerID: loggedInUser.VehicleVendorNo
      };

      console.log(payload);

      this.placingOrder = true;
      VehicleService.placeOrderParts(payload)
        .then(res => {
          // console.log(res.data);
          this.showSuccess(res.data.Message);
          this.resetForm();
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.placingOrder = false;
        });
    },

    resetForm() {
      this.currentStep = 1;
      this.formData = {
        orderType: "",
        orderMode: ""
      };
      this.items = [];
      this.repairOrderItems = {};

      this.stockReplenishmentItems = [];
      this.createdDemandItems = [];
      this.gotDetails = false;
      this.newItem = {
        JobNo: "",
        VIN: "",
        PartNo: "",
        Quantity: ""
      };
      this.partsDetails = {};
    },
    async addItem() {
      this.gettingPartDetails = true;
      let info = {
        Description: "",
        Unit_Price: 0.0
      };
      let res = null;

      try {
        res = await VehicleService.getPartsDetails(this.newItem.PartNo);
      } catch (error) {
        console.log(error);
      }

      if (res) {
        this.gettingPartDetails = false;

        if (res && res.data.Data.length > 0) {
          info = res.data.Data[0];
          console.log(info);
        }
      } else {
        this.gettingPartDetails = false;
      }

      /*
      VehicleService.getPartsDetails(this.newItem.PartNo)
        .then(res => {
          this.partsDetails = res.data.Data;
          this.gotDetails = true;
          console.log(this.partsDetails);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.gettingDetails = false;
        });
*/

      if (this.formData.orderType === "Stock Replenishment") {
        if (!this.newItem.PartNo || !this.newItem.Quantity) {
          this.showToast("Please Enter Part No. and Quantity");
          return;
        }

        this.stockReplenishmentItems.push({
          PartNo: this.newItem.PartNo,
          Quantity: this.newItem.Quantity,
          PartName: info.Description,
          UnitCost: info.Unit_Price
        });

        this.newItem.PartNo = "";
        this.newItem.Quantity = "";
      } else if (this.formData.orderType === "Created Demand") {
        if (
          !this.newItem.PartNo ||
          !this.newItem.Quantity ||
          !this.newItem.VIN
        ) {
          this.showToast("Please Enter all data");
          return;
        }

        this.createdDemandItems.push({
          PartNo: this.newItem.PartNo,
          Quantity: this.newItem.Quantity,
          VIN: this.newItem.VIN,
          PartName: info.Description,
          UnitCost: info.Unit_Price
        });

        this.newItem.PartNo = "";
        this.newItem.Quantity = "";
        this.newItem.VIN = "";
      } else if (this.formData.orderType === "Repair Order") {
        if (
          !this.newItem.PartNo ||
          !this.newItem.Quantity ||
          !this.newItem.VIN ||
          !this.newItem.JobNo
        ) {
          this.showToast("Please Enter all values");
          return;
        }

        if (this.repairOrderItems[this.newItem.JobNo]) {
          this.repairOrderItems[this.newItem.JobNo].push({
            PartNo: this.newItem.PartNo,
            Quantity: this.newItem.Quantity,
            VIN: this.newItem.VIN,
            PartName: info.Description,
            UnitCost: info.Unit_Price
          });
        } else {
          this.repairOrderItems[this.newItem.JobNo] = [
            {
              PartNo: this.newItem.PartNo,
              Quantity: this.newItem.Quantity,
              VIN: this.newItem.VIN,
              PartName: info.Description,
              UnitCost: info.Unit_Price
            }
          ];
        }

        this.newItem.PartNo = "";
        this.newItem.Quantity = "";
      }
    },

    deleteItem(arr, index) {
      if (confirm("Are you sure you want to delete this?")) {
        arr.splice(index, 1);
        this.$forceUpdate();
      }
    },

    submitPartNumbers() {
      if (confirm("Place order?")) {
        //alert("calling submit");
        this.partsDetails = {};

        if (this.formData.orderType === "Stock Replenishment") {
          this.stockReplenishmentItems.forEach(item => {
            this.partsDetails[item.PartNo] = item;
          });
        } else if (this.formData.orderType === "Created Demand") {
          this.createdDemandItems.forEach(item => {
            this.partsDetails[item.PartNo] = item;
          });
        } else if (this.formData.orderType === "Repair Order") {
          Object.keys(this.repairOrderItems).forEach(jobNo => {
            this.repairOrderItems[jobNo].forEach(item => {
              this.partsDetails[item.PartNo] = { ...item, JobNumber: jobNo };
            });
          });
        }

        if (Object.keys(this.partsDetails).length === 0) {
          this.showToast("Please add some parts first");
        } else {
          //alert("got to else");
          // console.log(this.partsDetails);
          const partsNumbersString = Object.keys(this.partsDetails).join(",");
          // console.log(partsNumbersString);

          this.gettingPartDetails = true;
          VehicleService.getPartsDetails(partsNumbersString)
            .then(res => {
              //alert("got to then res");

              //console.log(res.data);
              res.data.Data.forEach(parts => {
                //console.log(parts); //Description, No, Unit_Price

                console.log(this.partsDetails[parts.No]); //PartName,PartNo,Quantity,UnitCost
                /*
                console.log(parts.No);
                console.log(parts.Description);
                console.log(parts.Unit_Price);
                */
                this.partsDetails[parts.No].Description = parts.Description;
                this.partsDetails[parts.No].No = parts.No;
                this.partsDetails[parts.No].UnitCost = parts.Unit_Price;
                //this.partsDetails[parts.ItemNo].PartName = parts.Description;
                //this.partsDetails[parts.ItemNo].Quantity = "";
                /*this.partsDetails[parts.ItemNo].Quantity = 0;
                 */
              });
              //alert("changing step to 2");
              console.log(this.partsDetails);
              this.currentStep = 2;
              // this.partsDetails = res.data;
              this.gotDetails = true;
            })
            .catch(e => {
              console.log(e);
            })
            .finally(() => {
              this.gettingPartDetails = false;
            });
        }
      }
    }
  },
  watch: {},
  mounted() {
    // this.getDetails();
  }
};
</script>
<style>
.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.toast {
  font-size: 1.5rem;
}

.progress {
  font-size: 1.2rem;
}

.form-table {
  width: 100%;
  max-width: 800px;
}

.form-table td,
.form-table th {
  padding: 11px;
}

.vehicle-table {
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
}

.step__number {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaecee;
  color: #333;
}

.step--active .step__number {
  background-color: #35aa47;
  color: #fff;
}

.step--passed .step__number {
  background-color: red;
  color: #fff;
}

.step--passed .step__text {
  padding-left: 22px;
  position: relative;
}

.step--passed .step__text:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 10px;
  width: 5px;
  height: 10px;
  border-bottom: 2px solid #3a3a3a;
  border-right: 2px solid #3a3a3a;
  transform: rotate(45deg);
  /* opacity: 0;
  transition: opacity 0.2s ease-in-out; */
}

.flex-1 {
  flex: 1;
}
</style>
