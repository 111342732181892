<template>
  <div class="home">
    <b-container class="mt-10" fluid>
      <h3 class="py-2">
        <span class="text-capitalize">&nbsp;&nbsp;</span>
      </h3>
      <hr />
      <b-row>
        <b-col md="12">
          <div class="my-cart mb-4 mt-5">
            <h3>Parts Availability Check</h3>
            <b-row class="mt-4">
              <b-col md="3" v-for="step in steps" :key="step.number">
                <div
                  class="step"
                  :class="{
                    'step--active': currentStep === step.number,
                    'step--passed': currentStep > step.number
                  }"
                >
                  <div class="step__number">{{ step.number }}</div>
                  <div class="step__text ml-3">{{ step.text }}</div>
                </div>
              </b-col>
            </b-row>

            <b-progress
              class="mt-4"
              :value="currentStep"
              :max="steps.length"
              variant="success"
              striped
            ></b-progress>

            <div v-if="currentStep === 1">
              <h4 class="mt-5">
                Enter Parts Number
              </h4>

              <b-form-input
                placeholder="Enter parts number separated by comma"
                v-model="partsNumber"
              ></b-form-input>

              <b-container
                fluid
                :style="{
                  minHeight: '200px',
                  minWidth: '100%',
                  display: 'flex',
                  placeItems: 'center'
                }"
                v-if="gettingDetails"
              >
                <br />
                <b-spinner
                  label="Loading..."
                  style="margin: 0 auto"
                ></b-spinner>
              </b-container>

              <b-button
                size="sm"
                text="Button"
                variant="info"
                @click="getDetails"
                class="mt-4"
              >
                Continue
              </b-button>
            </div>

            <div v-if="currentStep === 2">
              <h4 class="mt-4">Supply Part Order Details</h4>

              <table
                class="vehicle-table table b-table table-striped table-hover mt-4"
              >
                <thead>
                  <tr>
                    <th>Item No</th>
                    <th>Name</th>
                    <th>Avail</th>
                    <th>Price</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in partsDetails" :key="item.ItemNo">
                    <td>
                      {{ item.No }}
                    </td>
                    <td>
                      {{ item.Description }}
                    </td>
                    <td>
                      {{ item.Availability ? "Yes" : "No" }}
                    </td>
                    <td>
                      {{ item.Unit_Price }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="d-flex justify-content-between">
                <b-button variant="secondary" @click="currentStep = 1">
                  Back
                </b-button>

                <div>
                  <b-button-group>
                    <!-- <b-button variant="info" @click="locationModal = true">
                      Availability by Location
                    </b-button> -->

                    <b-button variant="danger" @click="printModal = true">
                      Print View
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <TheModal v-model="locationModal">
      <table class="vehicle-table table b-table table-striped table-hover mt-4">
        <thead>
          <tr>
            <th>Item No</th>
            <th>Item Name</th>
            <th>Location</th>
            <th>Availability</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in partsDetails" :key="item.ItemNo">
            <td>
              {{ item.ItemNo }}
            </td>
            <td>
              {{ item.Description }}
            </td>
            <td v-html="item.AvailByLocation"></td>

            <td>
              {{ item.Availability ? "Yes" : "No" }}
            </td>
          </tr>
        </tbody>
      </table>
    </TheModal>

    <TheModal v-model="printModal">
      <table class="vehicle-table table b-table table-striped table-hover mt-4">
        <thead>
          <tr>
            <th>Item No</th>
            <th>Item Name</th>
            <th>Availability</th>
            <th>Price</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in partsDetails" :key="item.ItemNo">
            <td>
              {{ item.ItemNo }}
            </td>
            <td>
              {{ item.Description }}
            </td>
            <td>
              {{ item.Availability ? "Yes" : "No" }}
            </td>
            <td>
              {{ item.UnitCost }}
            </td>
          </tr>
        </tbody>
      </table>
    </TheModal>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
// import config from "../config";
import VehicleService from "../service/vehicle";
// import { getData } from "./../utils/storage";
import TheModal from "../components/TheModal.vue";

export default {
  data() {
    return {
      locationModal: false,
      printModal: false,
      steps: [
        {
          number: 1,
          text: "Items Selection"
        },
        {
          number: 2,
          text: "Confirm Details"
        }
      ],
      currentStep: 1,
      items: [],
      partsNumber: "",
      partsDetails: [],
      gettingDetails: false,
      gotDetails: false
    };
  },
  created() {
    // const loggedInUser = getData("userInfo");
  },
  computed: {},
  methods: {
    showToast(text, variant = "danger") {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true
      });
    },

    parseFile(e) {
      console.log(e);
      //TODO: Parse File

      this.items = [];

      this.items.push({
        ItemNo: "04465-0K240",
        Colour: "burgundy",
        Quantity: "2"
      });
      this.items.push({
        ItemNo: "04465-0K241",
        Colour: "red",
        Quantity: "1"
      });
      this.items.push({
        ItemNo: "04465-0K242",
        Colour: "blue",
        Quantity: "3"
      });
      this.items.push({
        ItemNo: "04465-0K243",
        Colour: "blue",
        Quantity: "5"
      });
    },

    getDetails() {
      const partsNumberArray = this.partsNumber.split(",");

      // console.log(partsNumberArray);

      if (!partsNumberArray[0]) {
        this.showToast("Please enter at least one parts number");
        return;
      }

      // const partsDetails = partsNumberArray.map((partNumber) => ({
      //   ItemNo: partNumber.trim()
      // }));

      this.gettingDetails = true;
      VehicleService.getPartsDetails(this.partsNumber)
        .then(res => {
          this.partsDetails = res.data.Data;
          this.gotDetails = true;
          this.currentStep = 2;
          // console.log(res.data);
        })
        .catch(e => {
          console.log(e);
          alert("Error getting details");
        })
        .finally(() => {
          this.gettingDetails = false;
        });
    },
    placeOrder() {
      //TODO: implement place order functionality
    }
  },
  watch: {},
  mounted() {
    // this.parseFile("a");
    // this.getDetails();
  },
  components: {
    TheModal
  }
};
</script>
<style>
.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.toast {
  font-size: 1.5rem;
}

.progress {
  font-size: 1.2rem;
}

.form-table {
  width: 100%;
  max-width: 800px;
}

.form-table td,
.form-table th {
  padding: 11px;
}

.vehicle-table {
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
}

.step__number {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaecee;
  color: #333;
}

.step--active .step__number {
  background-color: #35aa47;
  color: #fff;
}

.step--passed .step__number {
  background-color: red;
  color: #fff;
}

.step--passed .step__text {
  padding-left: 22px;
  position: relative;
}

.step--passed .step__text:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 10px;
  width: 5px;
  height: 10px;
  border-bottom: 2px solid #3a3a3a;
  border-right: 2px solid #3a3a3a;
  transform: rotate(45deg);
  /* opacity: 0;
  transition: opacity 0.2s ease-in-out; */
}
</style>
