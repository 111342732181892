import axios from "axios";
import config from "../config";
const BASE_URL = `${config.apiBaseUrl}:3330/api/`;
//const TNL_BASE_URL = "http://197.255.211.118/tnlservice/";
const TNL_BASE_URL = `${config.apiBaseUrl}/`; // `${config.apiBaseUrl}/tdms2017/`;//`${config.apiBaseUrl}`;
const TNLService_BASE_URL = `${config.apiBaseUrl}`;
const TNL_SERVICE_Image_Base_URL = `https://testservice.tdmsportal.com/images/`; //`${config.apiBaseUrl}/tnlservice/images/`
const https = require("https");

const TDMS_BASE_URL = `https://api.tdmsportal.com/tnlapi/api/`; //`http://13.80.104.30/tnlapi/api/` //`https://api.tdmsportal.com/tnlapi/api/` //`http://localhost/tnlapi/api/`

console.log(https);
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  timeout: 60000
});

export const axiosTNL = axios.create({
  baseURL: TNL_BASE_URL,
  timeout: 60000
});

export const axiosTDMS = axios.create({
  baseURL: TDMS_BASE_URL,
  timeout: 60000
});

export const axiosTNLService = axios.create({
  baseURL: TNLService_BASE_URL,
  timeout: 60000
});

export const axiosTNLServiceImages = axios.create({
  baseURL: TNL_SERVICE_Image_Base_URL,
  timeout: 60000
});

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  timeout: 60000
});

export const axiosTNLAPI = axios.create({
  baseURL: `${config.apiBaseUrl}/tnlapi/api/`,
  timeout: 60000
});

export const axiosNAV = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  baseURL: config.navBaseurl,
  timeout: 60000,
  auth: {
    username: config.navUsername,
    password: config.navPassword
  }
});

export const axiosALTService = axios.create({
  withCredentials: false,
  baseURL: config.navAltURL,
  timeout: 60000,
  auth: {
    username: config.navAltUsername,
    password: config.navAltPassword
  }
});

export const setPrivateHaders = token => {
  axiosPrivate.defaults.headers.common["token"] = "bearer " + token;
};
