/* eslint-disable no-undef */ /* eslint-disable no-undef */ /* eslint-disable
no-undef */ /* eslint-disable no-undef */ /* eslint-disable */
<template>
  <div class="home">
    <b-container class="mt-10" fluid>
      <h3 class="py-2">
        <span class="text-capitalize">&nbsp;&nbsp;</span>
      </h3>
      <hr />
      <b-row>
        <b-col md="12">
          <div
            v-if="isloading"
            style="
              min-height: 100vh;
              min-width: 100vw;
              display: flex;
              place-items: center;
            "
          >
            <br />
            <b-spinner label="Loading..." style="margin: 0 auto"></b-spinner>
          </div>

          <div class="my-cart mb-4 mt-5" v-if="!isloading">
            <h3>Current Balances</h3>

            <template v-if="balances.length > 0">
              <table class="table b-table cart-table" id="tbl">
                <!-- table-striped table-hover -->
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Account</th>
                    <th>Location</th>
                    <th>Limit</th>
                    <th>Balance</th>
                    <th>Terms</th>
                    <th>Oldest Invoice</th>
                    <!--  -->
                    <th style="width: 15%">Status</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" v-for="(item, i) in balances" :key="i">
                    <td>
                      <b>{{ item.Type }}</b>
                    </td>
                    <td>{{ item.AccountName }} ({{ item.AccountNo }})</td>
                    <td style="font-weight: bold;">
                      {{ item.Location }}
                    </td>
                    <td>{{ item.Limit | amount }}</td>
                    <td>{{ item.Balance | amount }}</td>
                    <td>{{ item.PaymentTerms }}</td>
                    <td>{{ item.OldestInvoice }}</td>
                    <!-- -->
                    <td>
                      <b-progress
                        height="2rem"
                        :value="item.value"
                        :variant="item.variant"
                      >
                        <b-progress-bar :value="item.value">
                          <span>
                            <strong :style="{ color: item.color }">{{
                              item.status
                            }}</strong>
                          </span>
                        </b-progress-bar>
                      </b-progress>
                    </td>
                    <td
                      @click="
                        showDetails(
                          item.AccountNo,
                          item.AccountName,
                          item.Location
                        )
                      "
                      class="pointer"
                    >
                      Details
                    </td>
                  </tr>
                </tbody>
                <!--<tfoot>
                  <tr>
                    <th>Total</th>
                    <th>{{ totalUnit }}</th>
                    <th colspan="2">{{ totalPrice | amount }}</th>
                  </tr>
                </tfoot>
                -->
              </table>
              <!-- <p class="text-right">
                <b-button variant="danger" @click="placeOrder"
                  >Place Order</b-button
                >
              </p>-->
            </template>
            <template v-else>
              <p class="text-center"></p>
            </template>
          </div>
        </b-col>
      </b-row>

      <div class="my-cart mb-4 mt-5" v-if="!isloading && selectedAccount != ''">
        <h4>
          Outstanding Invoices for account
          <u>{{ selectedAccount }}</u> {{ selectedAccountBranch }}
        </h4>

        <template v-if="statement.length > 0">
          <b-row>
            <b-table
              show-empty
              small
              stacked="md"
              :items="statement"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filterIncludedFields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              class="mt-3"
            ></b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              style="padding-left: 1rem"
              class="my-pagination"
            ></b-pagination>
          </b-row>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import config from "../config";
import VehicleService from "../service/vehicle";
import { getData, storeData } from "./../utils/storage";

export default {
  data() {
    return {
      balances: [],
      dealerid: 0,
      dealerUserID: 0,
      isCategoryA: false,
      cannotOrder: false,
      isloading: true,
      count: 0,
      statement: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        {
          key: "sNo",
          label: "",
          sortable: true,
          sortDirection: "asc",
          tdClass: (value, key, item) =>
            item.OverdueDays >= this.maxOverdue
              ? "text-danger font-weight-bold"
              : ""
        },
        {
          key: "PostDate",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
          formatter: "formatDateOnly",
          tdClass: (value, key, item) =>
            item.OverdueDays >= this.maxOverdue
              ? "text-danger font-weight-bold"
              : ""
        },
        {
          key: "DocNo",
          label: "No",
          sortable: true,
          sortDirection: "desc",
          tdClass: (value, key, item) =>
            item.OverdueDays >= this.maxOverdue
              ? "text-danger font-weight-bold"
              : ""
        },
        {
          key: "OverdueDays",
          label: "Days Past",
          sortable: true,
          sortDirection: "desc",
          tdClass: (value, key, item) =>
            item.OverdueDays >= this.maxOverdue
              ? "text-danger font-weight-bold"
              : ""
        },
        {
          key: "Amount",
          label: "Amount",
          sortable: true,
          sortDirection: "desc",
          formatter: "amount",
          tdClass: (value, key, item) =>
            item.OverdueDays >= this.maxOverdue
              ? "text-danger font-weight-bold"
              : ""
        }
      ],
      selectedAccount: "",
      selectedAccountBranch: "",
      maxOverdue: 0
    };
  },
  created() {
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;
    this.dealerUserID = loggedInUser.DealerUserID;
    this.isCategoryA = this.determineCategory(this.dealerid) == "A";
    this.getBalances();
  },
  computed: {},
  methods: {
    showToast(variant = "danger") {
      this.$bvToast.toast("You have overdue invoices." + this.orderWarning, {
        title: `Overdue Invoices`,
        variant: variant,
        solid: true,
        noAutoHide: true
      });
    },
    async showDetails(acctno, accountname, location) {
      this.selectedAccount = `${accountname} (${acctno})`;
      this.selectedAccountBranch = location;
      console.log(this.selectedAccount);
      //alert("acctno is: " + acctno);
      let that = this;
      that.statement = [];
      VehicleService.getAccountStatement(acctno) //(this.dealerid)
        .then(res => {
          this.isloading = false;
          console.log("res is: ", res);
          let { data } = res.data;
          console.log(data);
          console.table(data.statement);
          that.statement = data;
          that.maxOverdue = data[0].OverdueDays;
          console.log("that.maxOverdue is: ", that.maxOverdue);
          this.totalRows = this.statement.length;
        });
    },
    async getBalances() {
      /*alert("dgshgds");
      

      var spauth = require("node-sp-auth");
      console.log("spauth deyails");
      console.log(spauth);
      //var request = require("request-promise");
      //var $REST = require("gd-sprest");

      var url = "https://toyotang.sharepoint.com/sites/TNL";
      spauth
        .getAuth(url, {
          username: "helpdesk@toyotanigeria.com",
          password: "123Abc",
          online: true
        })
        .then(options => {
          // Log
          console.log("Connected to SPO");
          console.log(options);

          // Code Continues in 'Generate the Request'
        });
*/
      const dealerid = this.dealerid;

      storeData("canOrder", true);

      VehicleService.getAllBalances(this.dealerid) //(this.dealerid)//   this.dealerid = loggedInUser.DealerID;
        .then(res => {
          this.isloading = false;
          let { data } = res;
          //data = data.filter((n) => n.Limit > 0 && n.PaymentTerms > 0);

          data = data.filter(
            n =>
              n.DealerID == dealerid &&
              !(
                parseFloat(n.Limit) == parseFloat(0) &&
                parseFloat(n.Balance) == parseFloat(0)
              )
          );
          console.log("calling get filtered balances");
          console.log(data);
          data = data.sort(function(a, b) {
            return b.OldestInvoice - a.OldestInvoice;
          });

          let accountIDs = data.map(n => n.AccountNo);
          /*
          let retval = VehicleService.getLimit(accountIDs);
          console.log(retval);
*/
          //alert(accountIDs);
          storeData("accountIDs", JSON.stringify(accountIDs));
          var that = this;
          data.forEach(element => {
            const terms = parseInt(element.PaymentTerms);
            const oldestinvoice = parseInt(element.OldestInvoice);
            const percent = parseInt((oldestinvoice / terms) * 100);
            const daysleft = terms - oldestinvoice;
            if (element.Limit >= 2000000000) element.Limit = 1000000000;

            if (daysleft <= 0) {
              element.variant = "danger";
              element.color = "white";
              element.status = `Overdue by ${Math.abs(daysleft)} day(s)`;
              element.value = 100;
              that.cannotOrder = true;
              storeData("canOrder", false);
            } else if (daysleft <= 15) {
              element.variant = "danger";
              element.color = "white";
              element.value = percent;
              element.status = `Due in ${daysleft} day(s)`;
            } else if (daysleft > 15 && daysleft <= 30) {
              element.variant = "warning";
              element.color = "black";
              element.status = `Due in ${daysleft} day(s)`;
              element.value = percent;
            } else if (daysleft > 30) {
              element.variant = "success";
              element.color = "black";
              element.status = "Not due";
              element.value = percent;
            }
            element.Type =
              element.AccountNo.substr(0, 3) == "TDP" ? "Parts" : "Vehicle";
            //Vehicle coloring is always green but only red when exceeded
            if (element.Type == "Vehicle") {
              if (element.Balance > element.Limit) {
                element.status = `Limit exceeded`;
                element.variant = "danger";
                element.color = "white";
              } else {
                element.status = `Limit not exceeded`;
                element.variant = "success";
                element.color = "black";
              }
            }
            /*

           
            if (daysleft <= 0) {
              element.value = 100;
              element.status = `Overdue by ${Math.abs(daysleft)} days`;
              that.cannotOrder = true;
              storeData("canOrder", false);
            } else if (daysleft < 7) {
              element.value = percent;
              element.status = `Due in ${daysleft} days`;
            } else {
              element.value = percent;
              element.status = "Not due";
            }
*/
            if (element.Balance > element.Limit && element.Type != "Vehicle") {
              element.status += " and Limit exceeded";
              that.cannotOrder = true;
              storeData("canOrder", false);
            }
          });

          console.log("res returned is: ", res);
          this.balances = data;
          if (that.cannotOrder) {
            this.showToast();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {});
    },

    determineCategory(dealerid) {
      return config.categoryADealers.filter(x => x == dealerid).length > 0
        ? "A"
        : "B";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
    //placeOrder() {}
  },
  watch: {}
};
</script>
<style>
.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.toast {
  font-size: 1.5rem;
}

.progress {
  font-size: 1.2rem;
}
</style>
